<template>
    <div>
        <img :src="A840AM" />
        <div class="bidding-all" :style="{backgroundImage: `url(${A84lQk})`}">
            <div class="bidding-list">
                <div class="bidding-list-div" v-for="item in biddingList" :key="item.id" @click="$router.push({path: '/details', query:{id: item.id, type: 'tender'}})">
                    <div class="bidding-list-div-left">
                        <div class="bidding-list-div-left-tit">{{stringTime(item.tenderTime)[2]}}</div>
                        <div>{{stringTime(item.tenderTime)[0]}}/{{stringTime(item.tenderTime)[1]}}</div>
                    </div>
                    <div class="bidding-list-div-right">
                        <div class="bidding-list-div-right-tit">{{item.tenderTitle}}</div>
                        <div class="bidding-list-div-right-text">{{item.tenderSynopsis}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="bidding-paging">
            <div class="bidding-paging-up">上一页</div>
            <div class="bidding-paging-page">
                <div>1</div>
                <div>2</div>
            </div>
            <div class="bidding-paging-down">下一页</div>
        </div> -->
        <div class="bidding-paging" v-if="pageList.length > 1">
            <div class="bidding-paging-up" v-if="params.pageNumber != 1" @click="pageClick(params.pageNumber - 1)">上一页</div>
            <div class="bidding-paging-page">
                <div v-for="item in pageList" :key="item" :class="{'bidding-paging-page-color': item == params.pageNumber}" @click="pageClick(item)">{{item}}</div>
            </div>
            <div class="bidding-paging-down" v-if="params.pageNumber != pageList.length" @click="pageClick(params.pageNumber + 1)">下一页</div>
        </div>
    </div>
</template>
<script>
import { A840AM, A84lQk } from '@/utils/imgUrl'
import { tenderPaging } from '@/api'
export default {
    name: 'bidding',
    data(){
        return{
            params: {
                condition: {
                    tenderContent: '', //招标通知内容
                    tenderTitle: '', //招标通知标题
                    tenderSynopsis: '', //招标通知摘要
                    tenderWriter: '', //招标通知发布者
                    startTime: '',
                    endTime: '',
                    sort: 'DESC',//ASC升序 DESC 降序
                    sortColumn: 'tender_time',
                    time: []
                },
                pageNumber: 1,
                pageSize: 3
            },
            biddingList: [],
            pageList: []
        }
    },
    computed:{
        A840AM: () => A840AM,
        A84lQk: () => A84lQk,
    },
    created(){
        this.getList()
    },
    methods:{
        getList(){
            this.pageList = []
            tenderPaging(this.params).then(res => {
                console.log(res)
                this.biddingList = res.records
                for (let index = 1; index <= res.pages; index++) {
                    this.pageList.push(index)
                }
                console.log(this.pageList)
            })
        },
        pageClick(index){
            this.params.pageNumber = index
            this.getList()
        },
        stringTime(time){
            var arr = time.split('-')
            return arr
        }
    }
}
</script>
<style lang="scss" scoped>
.bidding-all{
    position: relative;
    width: 1300px;
    height: 700px;
    margin: auto;
    background-size: 100%;
    background-position-y: center;
}
.bidding-list{
    position: absolute;
    top: 190px;
    left: 210px;
    right: 210px;
}
.bidding-list-div{
    display: flex;
    margin-bottom: 76px;
}
.bidding-list-div:last-child{
    border: none;
}
.bidding-list-div-left{
    width: 84px;
    height: 84px;
    color: #666666;
    background: #f2f2f2;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #6b6b6b;
    font-weight: bold;
}
.bidding-list-div-left-tit{
    font-size: 16px;
    margin-bottom: 3px;
}
.bidding-list-div-right{
    flex: 1;
}
.bidding-list-div-right-tit{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    font-size: 24px;
    color: #4b4b4b;
    font-weight: bold;
    cursor: pointer;
}
.bidding-list-div-right-tit:hover{
    color: #0ab5a3;
}
.bidding-list-div-right-text{
    font-size: 14px;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
}
.bidding-paging{
    width: 1300px;
    margin: 0 auto 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    div{
        cursor: pointer;
        margin-right: 35px;
    }
}
.bidding-paging-page{
    display: flex;
}
.bidding-paging-page-color{
    color: #079deb;
}
.bidding-paging-page div:last-child{
    margin-right: 0 !important;
}
.bidding-paging-down{
    margin: 0 !important;
}
</style>